import React from 'react';
import Navbar from '../components/Navbar';
import Footer from './Footer';

const NewsBlogs = () => {
  const blogPosts = [
    {
      id: 1,
      title: 'Unlock the Benefits of Owning an Aekaksh Play School Franchise',
      content: `Aekaksh Play School offers a unique franchise model that empowers aspiring entrepreneurs to build a successful business in the education sector...`,
      date: 'October 12, 2024',
    },
    {
      id: 2,
      title: 'Aekaksh Franchise Model: A Turnkey Solution for Aspiring Entrepreneurs',
      content: `At Aekaksh, we understand that starting a play school from scratch can be daunting. That's why our franchise model is a complete turnkey solution...`,
      date: 'October 11, 2024',
    },
    {
      id: 3,
      title: 'Special Offers for Women Entrepreneurs: Aekaksh Empowers You!',
      content: `Aekaksh Play School is committed to empowering women entrepreneurs by offering exclusive benefits and support...`,
      date: 'October 10, 2024',
    },
    {
      id: 4,
      title: 'Why Aekaksh Play School is the Perfect Franchise for You',
      content: `The Aekaksh franchise model is ideal for anyone passionate about education and looking to make a meaningful impact...`,
      date: 'October 9, 2024',
    },
    {
      id: 5,
      title: 'How Aekaksh Supports You Every Step of the Way',
      content: `From the moment you decide to become an Aekaksh franchisee, our team is with you every step of the way...`,
      date: 'October 8, 2024',
    },
    {
      id: 6,
      title: 'Aekaksh Play School: A Proven Business Model for Success',
      content: `Our franchise model is built on years of experience and a deep understanding of early childhood education...`,
      date: 'October 7, 2024',
    },
    {
      id: 7,
      title: 'Take Advantage of Aekaksh\'s Limited-Time Offers for New Franchisees',
      content: `To celebrate our expansion in Hyderabad, Aekaksh Play School is offering limited-time incentives for new franchisees...`,
      date: 'October 6, 2024',
    },
    {
      id: 8,
      title: 'The Aekaksh Advantage: Training, Support, and Innovation',
      content: `One of the key benefits of the Aekaksh franchise model is our focus on continuous training and support...`,
      date: 'October 5, 2024',
    },
    {
      id: 9,
      title: 'Aekaksh Franchise: Low Investment, High Returns',
      content: `Investing in an Aekaksh franchise is a cost-effective way to enter the lucrative education market...`,
      date: 'October 4, 2024',
    },
    {
      id: 10,
      title: 'How to Become an Aekaksh Franchisee: A Step-by-Step Guide',
      content: `Becoming an Aekaksh franchisee is a simple and straightforward process. Start by filling out our online application form...`,
      date: 'October 3, 2024',
    },
    // Adding the new blog posts
    {
      id: 11,
      title: 'Join the Aekaksh Play School Franchise Revolution: Nurturing Tomorrow’s Leaders',
      content: `Start your journey towards shaping young minds and building a legacy in early childhood education.`,
      date: 'October 2, 2024',
    },
    {
      id: 12,
      title: 'Aekaksh Play School: Empowering Women Entrepreneurs in Early Education',
      content: `Unlock a rewarding career path with a franchise that values empowerment and growth.`,
      date: 'October 1, 2024',
    },
    {
      id: 13,
      title: 'Transformative Learning with Aekaksh Play School: Your Franchise Opportunity Awaits',
      content: `Step into a world where innovation in education meets business success.`,
      date: 'September 30, 2024',
    },
    {
      id: 14,
      title: 'Invest in the Future with Aekaksh Play School: Franchise with Purpose',
      content: `Discover how owning an Aekaksh franchise can secure your future and inspire the next generation.`,
      date: 'September 29, 2024',
    },
    {
      id: 15,
      title: 'Build a Legacy with Aekaksh Play School: Franchise Opportunities in Hyderabad',
      content: `Seize the opportunity to lead the way in premium early childhood education.`,
      date: 'September 28, 2024',
    },
    {
      id: 16,
      title: 'Why Aekaksh Play School Franchise is the Smartest Investment for 2024',
      content: `Invest in a play school franchise that promises growth, stability, and long-term success.`,
      date: 'September 27, 2024',
    },
    {
      id: 17,
      title: 'From Dreams to Reality: Start Your Aekaksh Play School Franchise Today',
      content: `Turn your passion for education into a thriving business with Aekaksh.`,
      date: 'September 26, 2024',
    },
    {
      id: 18,
      title: 'Aekaksh Play School Franchise: Where Innovation Meets Early Education',
      content: `Be part of a pioneering brand that’s redefining the standards of preschool education.`,
      date: 'September 25, 2024',
    },
    {
      id: 19,
      title: 'Aekaksh Play School: Helping You Build a Bright Future for Children and Yourself',
      content: `Shape the leaders of tomorrow with a franchise model built for success.`,
      date: 'September 24, 2024',
    },
    {
      id: 20,
      title: 'Secure Your Place in the Education Sector with Aekaksh Play School Franchise',
      content: `Join the franchise transforming education one child at a time.`,
      date: 'September 23, 2024',
    },
    {
      id: 21,
      title: 'Aekaksh Play School: The Future of Early Education in Your Hands',
      content: `Become a franchise partner and lead the way in holistic, child-centered learning.`,
      date: 'September 22, 2024',
    },
    {
      id: 22,
      title: 'Aekaksh Play School Franchise: Empowering Entrepreneurs, Transforming Education',
      content: `Step into a proven franchise model that delivers both personal fulfillment and financial success.`,
      date: 'September 21, 2024',
    },
    {
      id: 23,
      title: 'Lead the Change in Early Childhood Education with Aekaksh Play School',
      content: `Become part of an innovative play school network that puts children first.`,
      date: 'September 20, 2024',
    },
    {
      id: 24,
      title: 'Franchise with Aekaksh Play School: Building Foundations, Inspiring Futures',
      content: `Inspire the next generation while building a successful business with Aekaksh.`,
      date: 'September 19, 2024',
    },
    {
      id: 25,
      title: 'Why Aekaksh Play School Franchise is a Game-Changer for New Entrepreneurs',
      content: `Start your entrepreneurial journey with the backing of a trusted and growing brand.`,
      date: 'September 18, 2024',
    },
    {
      id: 26,
      title: 'Aekaksh Play School Franchise: Creating a Path for Women Entrepreneurs',
      content: `Discover exclusive opportunities to own a franchise designed for growth and empowerment.`,
      date: 'September 17, 2024',
    },
    {
      id: 27,
      title: 'Invest in Education, Invest in Success: Aekaksh Play School Franchise Opportunity',
      content: `Join an educational movement that is committed to nurturing young minds and creating change.`,
      date: 'September 16, 2024',
    },
    {
      id: 28,
      title: 'Aekaksh Play School Franchise: The Key to Your Entrepreneurial Success',
      content: `Take charge of your future with a low-investment, high-impact play school franchise.`,
      date: 'September 15, 2024',
    },
    {
      id: 29,
      title: 'Own a Piece of the Future: Aekaksh Play School Franchise Opportunities in India',
      content: `Empower the leaders of tomorrow with a franchise built on innovation and care.`,
      date: 'September 14, 2024',
    },
    // {
    //   id: 30,
    //   title: 'Aekaksh Play School: Leading the Way in Premium Early Childhood Education',
    //   content: `Partner with a brand that’s reshaping how early education is delivered and experienced.`,
    //   date: 'September 13, 2024',
    // },
    // {
    //   id: 31,
    //   title: 'Why Franchise with Aekaksh Play School? Grow with Us, Impact Generations',
    //   content: `Expand your horizons with a play school that’s driving educational excellence nationwide.`,
    //   date: 'September 12, 2024',
    // },
    // {
    //   id: 32,
    //   title: 'Become a Leader in Education: Franchise with Aekaksh Play School',
    //   content: `Transform your passion for education into a successful business model with our support.`,
    //   date: 'September 11, 2024',
    // },
    // {
    //   id: 33,
    //   title: 'Aekaksh Play School Franchise: Your Gateway to a Successful Business',
    //   content: `Join a community dedicated to fostering growth and creativity in early education.`,
    //   date: 'September 10, 2024',
    // },
    // {
    //   id: 34,
    //   title: 'Aekaksh Play School: Join Us in Making a Difference in Early Childhood Education',
    //   content: `Empower your community by providing quality education and care to young learners.`,
    //   date: 'September 9, 2024',
    // },
    // {
    //   id: 35,
    //   title: 'Aekaksh Play School: Innovating Early Education through Franchising',
    //   content: `Be a part of a transformative journey that’s setting new standards in preschool education.`,
    //   date: 'September 8, 2024',
    // },
    // {
    //   id: 36,
    //   title: 'Why Aekaksh Play School is Your Best Franchise Choice in 2024',
    //   content: `Explore the unique benefits of partnering with Aekaksh in the ever-evolving education landscape.`,
    //   date: 'September 7, 2024',
    // },
  ];

  return (
    <div>
      <Navbar />

      <h1 style={{margin:'auto',textAlign:'center',marginBottom:'20px'}}>
        <span className="unique-allyouneed-title-black">Latest News<span className="unique-allyouneed-title-purple"> and </span>Blogs</span>
      </h1>

      <div className="news-blogs">
        {blogPosts.map((post) => (
          <div key={post.id} className="news-blogs__item">
            <h2 className="news-blogs__title">{post.title}</h2>
            <p className="news-blogs__content">{post.content}</p>
            {/* <small>{post.date}</small> */}
          </div>
        ))}
      </div>
      <Footer />
    </div>
  );
};

export default NewsBlogs;



